<template>
  <div>
    <ViewTemplateConfiguration :templateList="templateList" :showSearch="false" :showHorizontalFilter="false"
      :propsParam="propsParam">
    </ViewTemplateConfiguration>
    <Modal title="Documento" :width="1000" v-show="showModal('documentVersion')">
      <DocumentPreview :editModeDocumentVersion="true"/>
    </Modal>
  </div>
</template>

<script>
import ViewTemplateConfiguration from "@nixweb/nixloc-ui/src/component/template/ViewTemplateConfiguration";
import Modal from "@nixweb/nixloc-ui/src/component/forms/Modal";
import DocumentPreview from "../../adm/document/DocumentPreview";

import { mapMutations, mapGetters } from "vuex";

export default {
  name: "DocumentVersionList",
  components: {
    ViewTemplateConfiguration,
    Modal,
    DocumentPreview
  },
  props: {
    rentId: String,
  },
  data() {
    return {
      id: "",
      templateList: {
        urlGetApi: "/api/v1/shared/document-version/get-all",
        urlDeleteAllApi: "/api/v1/shared/document-version/delete",
        headerTable: [
          {
            field: "identification",
            title: "Nome (Versão)",
            type: "link",
            eventName: "documentVersionUpdate",
          },
        ],
      },
      propsParam: {
        any: this.rentId,
      },
    };
  },
  computed: {
    ...mapGetters("generic", ["showModal", "event"]),
  },
  methods: {
    ...mapMutations("generic", ["openModal", "hideModal", "removeLoading"]),
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "documentVersionUpdate") {
          this.openModal("documentVersion");
        }
      },
      deep: true,
    },
  },

};
</script>
<style>
.badge-default {
  background-color: #eeeeee;
  font-size: 13px !important;
}

.box-finance {
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin-right: 8px;
}

.finance-delay {
  background-color: red;
}

.finance-paid {
  background-color: #009183;
}

.finance-today {
  background-color: #ff8a1b;
}

.finance-scheduled {
  background-color: #3d4eae;
}

.payable {
  color: red;
}

.receivable {
  color: darkblue;
}

.div-btn-paymentRent-create {
  margin-bottom: 15px;
}

.hide-link {
  display: none;
}
</style>